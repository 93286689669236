import dustmon1 from "../images/dustmon-1.png";
import dustmon2 from "../images/dustmon-2.png";
import dustmon3 from "../images/dustmon-3.png";
import dustmon4 from "../images/dustmon-4.png";
import dustmon5 from "../images/dustmon-5.png";
import dustmon6 from "../images/dustmon-6.png";
import dustmon7 from "../images/dustmon-7.png";
import dustmon8 from "../images/dustmon-8.png";
import dustmon9 from "../images/dustmon-9.png";
import dustmon10 from "../images/dustmon-10.png";
import dustmon11 from "../images/dustmon-11.png";
import { animated, useSpring } from '@react-spring/web'
import React, { useEffect } from "react";
import { transform } from "typescript";

export default function Dustmon(props:{disableShadow?:boolean,isJump?:boolean,type?:number,filpped?:boolean,speed?:number}){
  const [time, setTime] = React.useState(0);
    React.useEffect(() => {
      const timer = window.setInterval(() => {
        setTime(prevTime => prevTime + 1); // <-- Change this line!
      }, props.speed??300);
      return () => {
        window.clearInterval(timer);
      };
    }, []);

    const dustmons=[dustmon1,dustmon2,dustmon3,dustmon4,dustmon5,dustmon6,dustmon7,dustmon8,dustmon9,dustmon10,dustmon11]
    const selectedElement= props.type?props.type<dustmons.length-1?dustmons[props.type]:dustmons[Math.floor(Math.random() * dustmons.length)]:dustmons[Math.floor(Math.random() * dustmons.length)];

    let styles = useSpring({
        y:time%2==0? 0 : 20,
        transform:time%2==0?`scale(${props.filpped?"-":""}0.95,1.05)`:`scale(${props.filpped?"-":""}1.05,0.98)`
      })

    return <div className="">
        <animated.img src={selectedElement} className="h-20 w-20" style={styles}/>
        <div className={`w-20 h-5  bg-zinc-400/50 ${props.disableShadow?"hidden":""}`} style={{"borderRadius":"50%"}}></div>
        </div>
}