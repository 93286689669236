import create from 'zustand'
import { Contract } from "web3-eth-contract";
interface MintSiteState{
    contract?:Contract
    setContract:(contract:Contract)=>Promise<void>
    walletAddress?:string
    setWalletAddress:(wallet:string)=>Promise<void>
    unitPrice?:number
    updateUnitPrice:()=>Promise<void>
    purChaseCount?:number
    setPurchaseCount:(count:number)=>Promise<void>
    mint:()=>Promise<void>
    totalSupply?:number
    updateTotalSupply:()=>void
    isMintOpen?:boolean,
    updateIsMintOpen:()=>Promise<void>,
    addPurchaseCount:()=>void,
    dropPurchaseCount:()=>void
}
export const useStore = create<MintSiteState>((set,get) => ({
    contract:undefined,
    setContract:async(contract:Contract)=>{
        
        await set(()=>({contract}))
        await get().updateUnitPrice()
    },
    walletAddress:undefined,
    setWalletAddress:async(walletAddress:string)=>{

      
        set(()=>({walletAddress}))
        await get().updateUnitPrice()
        await get().updateTotalSupply()
        await get().updateIsMintOpen()
    },
    unitPrice:undefined,
    updateUnitPrice:async()=>{
        const contract=get().contract
        const address=get().walletAddress
        if(!contract||!address){
            
            return //TODO: error handling
        }else{
            const unitPrice = await contract.methods.getPrice().call({from:address}) as number
       
            set(()=>({unitPrice}))
        } 
        
    },
    totalSupply:0,
    updateTotalSupply:async()=>{
        const contract=get().contract
        const address=get().walletAddress
        if(!contract||!address){
            return //TODO: error handling
        }
        const totalSupply = await contract.methods.totalSupply().call({from:address}) as number
    
        set(()=>({totalSupply}))
    },
    purChaseCount:1,
    setPurchaseCount:async(count:number)=>{

        set(()=>({purChaseCount:count}))
    },
    addPurchaseCount:async()=>{
        await get().updateUnitPrice()
        await get().updateUnitPrice()
        const price=get().unitPrice
    
        if(price==0){
            set(()=>({purChaseCount:1}))
        }else
        {
            set((state)=>{
                 if(state.purChaseCount && state.purChaseCount<5){
                    return {purChaseCount:state.purChaseCount+1}
                 }
                 return {}
                
            })
        }

       
    },
    dropPurchaseCount:async()=>{
        await get().updateUnitPrice()
        await get().updateUnitPrice()
        set((state)=>{
            if(state.purChaseCount && state.purChaseCount>1){
               return {purChaseCount:state.purChaseCount-1}
            }
            return {}
           
       })
    },
    mint:async()=>{
        const contract=get().contract
        const address=get().walletAddress
        const count=get().purChaseCount
        const price=get().unitPrice
  
        if(!contract||!address||!contract||!address){
            return //TODO: error handling
        }else{
           
           
                const tx = await contract.methods.safeMint(get().purChaseCount).send({from:address,value:(count??0)*(price??0)})
                await get().updateTotalSupply()
        }
    },
    isMintOpen:false,
    updateIsMintOpen:async()=>{
        const contract=get().contract
        const address=get().walletAddress
       
        if(!contract||!address){
            return //TODO: error handling
        }else{

            const isMintOpen = await contract.methods.paused().call({from:address}) as boolean
            set(()=>({isMintOpen:!isMintOpen}))
        } 
       
    }
    
}))
