interface Props {
    total:number;
    current:number;
}

export function ProgressBar(props:Props){
    return(<div>
        <div className="w-full rounded-full overflow-hidden bg-indigo-700" >
        <div style={{width:`${props.current/props.total*100}%`
}} className="bg-sky-500"><div className="text-right mx-1 text-white">{(props.current/props.total).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2})}</div></div>
    </div>  
    <div className="text-zinc-100">{`${props.current}/${props.total}`}</div>
    </div>)
    
}