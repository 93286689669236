import React from "react";
import "./App.css";
import Web3 from "web3";
import { useStore } from "./zustand/zustand";
import { weiToEth } from "./Helper/priceHelper";
import { DropDown, DropDownOption } from "./componment/Dropdown";
import { ProgressBar } from "./componment/ProgressBar";
import twitter from "./images/twitter.png";
import opensea from "./images/opensea.png";
import discord from "./images/discord.png";
import banner2 from "./images/banner2.png"
import logo from "./images/logo.png"
import story from "./images/story.png"
import intro from "./images/intro.png"
import Dustmon from "./componment/Dustmon";
import Hack from "./images/team1.png"
import BananaX from "./images/team2.png"
import noWallet from "./images/no-wallet.png"
import connectedWallet from "./images/connected-wallet.png";
const music = require("./asset/bg-music.mp3");
const { ABI, contractAddress } = require("./contracts/contractsInfo");
function App() {
  const store = useStore((state) => state);
  const connectWallet = async () => {
    if (window.ethereum) {
      await window.ethereum.send("eth_requestAccounts");
      window.web3 = new Web3(window.ethereum);
      const account = await window.web3.eth.getAccounts();
      store.setWalletAddress(account[0]);
      store.setContract(new window.web3.eth.Contract(ABI, contractAddress));
    } else {
      alert("this browser does not support wallets");
    }
  };
  const Dustmons = [<Dustmon type={1} disableShadow speed={600} />,
  <Dustmon type={2} disableShadow speed={400} />,
  <Dustmon type={6} disableShadow speed={200} />,
  <Dustmon type={7} disableShadow speed={700} />,
  <Dustmon type={9} disableShadow speed={300} />]
  React.useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async (e: any) => {
        store.setWalletAddress(e[0]);
      });
    }
    const imagesToBePreloaded = [noWallet, connectedWallet]
    imagesToBePreloaded.forEach(image => { new Image().src = image })
  }, []);
  const options: DropDownOption[] = [...new Array(5)].map((_, i) => ({
    label: `  ${i + 1}  `,
    onClick: () => {
      store.setPurchaseCount(i + 1);
    },
    value: i + 1,
  }));
  // var audio = new Audio(music);

  // document.onclick = function () {
  //   audio.play();
  // }
  return (
    <div>
      {/* {"preload images"} */}
      <div>
        <img className="hidden" src={connectedWallet} />
      </div>

      <div className="app font-extrabold">
        <div className="bg-zinc-900">


          <div className="flex align-middle items-center space-x-4 pt-2 pb-3 px-6 bg-zinc-900 justify-between container mx-auto">
            <div className="flex items-center w-20 md:w-40">
              <a>
                <img src={logo} className="h-8 md:h-16"></img>
              </a>

            </div>
            <div className="title text-white text-6xl hidden md:block">
              DUSTMON
            </div>
            <div className="flex items-center space-x-4 w-20 md:w-40">


              <div style={{ marginTop: "2px", marginBottom: "2px" }}>
                <a href="https://twitter.com/dustmonnft" target={"_blank"}>
                  <img src={twitter} />
                </a>
              </div>
              <div>
                <a href="https://opensea.io/collection/dustmonsnft">
                  <img src={opensea} />
                </a>
              </div>
              <div style={{ marginTop: "3px", marginBottom: "3px" }}>
                <a href="https://discord.gg/6sTeXVtbkj" target={"_blank"}>
                  <img src={discord} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto text-center h-full flex flex-col">
          <div className="flex flex-col text-zinc-100 flex-none">
            <div className="flex justify-between pt-6">



            </div>
          </div>

          <div className="flex justify-center">
            <div className="w-60 h-60 ">

            </div>
          </div>
          {/* <div className="mx-auto mt-20 flex-none pb-10">
          <ShowCase />
        </div> */}

        </div>
      </div>
      <div></div>
      <div className="">
        <div className="md:w-50">

        </div>
        <div className="bg-zinc-900">
          <img src={banner2} />
        </div>
        <div className="flex flex-col bg-zinc-900 items-center">
          <div>

          </div>


        </div>
        
        <div className="bg-zinc-900 border-none">
          <div className="container lg:flex px-4 bg-zinc-900  pb-10  mx-auto">
            <div className="bg-zinc-900 flex items-center mb-6">
              <img src={story} className="my-auto rounded-2xl  md:w-3/4" />
            </div>
            <div className="bg-zinc-900 px-4 md:flex">
              <div className="m-4">
                <div className="text-white text title text-3xl b">
                  Dr.Tom's Research Note
                </div>
                <div className="text-white title mt-4">
                  June 29th, 2050<br />
                  We managed to discover the cause of the massive allergic symptom within the territories of Catty Kingdom. What are these small creatures under mircoscope??
                </div>
              </div>
              <div>
                <div className="bg-teal-500 rounded-full m-4">
                  <img src={intro} />
                </div>
              </div>
            </div>

          </div>
          <div className="px-4 flex lg:flex-row flex-col items-center lg:items-end justify-center pt-12">
            {store.walletAddress ?
              <div className="flex flex-col lg:flex-row  ">
                <div>

                </div>
                <div className="flex flex-col bg-zinc-900 items-center  lg:relative">
                  <div className="hidden lg:block lg:absolute top-14 right-12 bg-yellow-400 w-4 h-4 rounded-full" />
                  <div className="hidden lg:block lg:absolute top-6 right-8 bg-yellow-400 w-6 h-6 rounded-full" />
                  {store.isMintOpen === true ? <div
                    className={`lg:absolute lg:-top-24 lg:-right-48 rounded-2xl w-102 my-6 max-w-full font-black rounded-full bg-yellow-400 py-3 px-8 text-lg title`}
                  >

                    <div>

                      <div>{store.walletAddress.substring(0, 6)}.. Connected</div>
                      <div>Unit Price: {weiToEth(store.unitPrice)} eth</div>
                  
                      {store.unitPrice != 0 ?

                        <>
                        
                          <div className="hover:underline ease-in-out transition" onClick={store.addPurchaseCount}>➤ Get Dusty!</div>
                          <div className="hover:underline ease-in-out transition" onClick={store.dropPurchaseCount}>➤ Clean it up!</div>
                        </>:<></>
                        
                      }
                      </div></div> : <div><div
                    className={`lg:absolute lg:-top-16 lg:-right-36 rounded-2xl w-48 my-6 max-w-full font-black rounded-full bg-yellow-400 py-3 px-8 text-lg title`}
                  >
                   {store.isMintOpen === false?"Mint Paused :(":"Loading.."} </div></div>
              
                }
                <img src={connectedWallet} className="w-60" />
              </div>

              </div>
          :
          <div className="flex flex-col bg-zinc-900 items-center relative">
            
            <div className="hidden lg:block lg:absolute top-14 right-12 bg-yellow-400 w-4 h-4 rounded-full" />
            <div className="hidden lg:block lg:absolute  top-6 right-8 bg-yellow-400 w-6 h-6 rounded-full" />
            <a
              className="lg:absolute lg:-top-16 lg:-right-48 rounded-2xl my-6 max-w-full font-black rounded-full bg-yellow-400 py-3 px-8 text-xl title hover:bg-yellow-500 ease-in-out transition"
              href="https://opensea.io/collection/dustmonsnft"
            >
              Buy at Opensea
            </a>          
            <img src={noWallet} className="w-60" />
            <div className="text-white title">
                Sorry, already sold out!
            </div>
          </div>}
          {
            store.walletAddress && store.isMintOpen &&
            <>
              <div>
                <div className="flex space-x-4">
                  <div className="lg:flex space-x-4 hidden ">
                  {Dustmons.filter((_v, i) => i < (store.purChaseCount ?? 0))}
                  </div>
                  <div onClick={store.mint} className="my-6 max-w-full font-black rounded-full bg-yellow-400 py-3 px-8 text-xl title hover:bg-yellow-500 ease-in-out transition">
                    Mint {(store.purChaseCount ?? 0)} ➤
                  </div>
                </div>
                <div className="text-white title">
                  <ProgressBar current={store.totalSupply ?? 0} total={10000} />
                </div>
              </div>
            </>
          }
        </div>

      </div>
      <div className="breaker1 h-28" />
      <div className="p-10 container mx-auto my-4 border-none">
        <div className="text-zinc-900 text title text-5xl">
          Road Map
        </div>
        <div className="relative flex-col flex md:flex-row mt-10 items-center space-x-4  justify-around bg-amber-300 p-10 rounded-3xl ">
          <Dustmon type={1} />
          <div className=" absolute -top-4 left-2 bg-teal-700 text-zinc-100 title text-2xl rounded-full px-10 py-2 max-h-12 ">
            2022-10-30
          </div>

          <div className="text-zinc-900 text title text-2xl mt-2 md:mt-0 md:w-1/2">
            Collect wallect address from our fans to airdrop Dustmon to them {":)"}
          </div>
        </div>
        <div className="relative flex-col flex md:flex-row mt-10 items-center space-x-4  justify-around bg-green-300 p-10 rounded-3xl ">
          <Dustmon type={2} />
          <div className=" absolute -top-4 left-2 bg-violet-700 text-zinc-100 title text-2xl rounded-full px-10 py-2 max-h-12 ">
            2022-10-31
          </div>


          <div className="text-zinc-900 text title text-2xl mt-2 md:mt-0  md:w-1/2">
            Public free mint. Total supply will be 10000 Dustmons, first 500 tokens are reserved by the project team.
          </div>
        </div>
        <div className="relative flex-col flex md:flex-row mt-10 items-center space-x-4  justify-around bg-pink-300 p-10 rounded-3xl ">
          <Dustmon type={6} />
          <div className=" absolute -top-4 left-2 bg-green-700 text-zinc-100 title text-2xl rounded-full px-10 py-2 max-h-12 ">
            2022-10-31
          </div>


          <div className="text-zinc-900 text title text-2xl mt-2 md:mt-0  md:w-1/2">
            Reveal the token at Halloween night.
          </div>
        </div>
        <div className="relative flex-col flex md:flex-row mt-10 items-center space-x-4  justify-around bg-cyan-300 p-10 rounded-3xl ">
          <Dustmon type={4} />
          <div className=" absolute -top-4 left-2 bg-pink-700 text-zinc-100 title text-2xl rounded-full px-10 py-2 max-h-12 ">
            2023 Q1-Q2
          </div>


          <div className="text-zinc-900 text title text-2xl mt-2 md:mt-0  md:w-1/2">
            Our next amazing project will be comming, so what it could be?
          </div>
        </div>


      </div>
      <div className="breaker2 h-28" />
      <div className=" bg-zinc-900 border-none">
        <div className="container mx-auto p-10">
          <div className="text-zinc-100 text title text-5xl mb-10">
            Team
          </div>
          <div className="md:flex justify-around space-x-6">
            <div className="flex-col flex items-center justify-items-center justify-center  md:w-1/2">
              <img src={Hack} className="w-60 h-60 rounded-full" />
              <div className="text-zinc-900 text title text-2xl mb-10 mt-5 bg-lime-500 px-10 py-2 rounded-full">
                BOp
              </div>
              <div className="text-zinc-100 text title text-xl mb-10 md:w-2/3">
                An artist who likes bakery and candy
              </div>
            </div>
            <div className="flex-col flex items-center justify-items-center justify-center md:w-1/2">
              <img src={BananaX} className="w-60 h-60 rounded-full" />
              <div className="text-zinc-900 text title text-2xl mb-10 mt-5 bg-lime-500 px-10 py-2 rounded-full">
                BananaX San
              </div>
              <div className="text-zinc-100 text title text-xl mb-10 md:w-2/3">
                A software engineer allergic to animal hair
              </div>
            </div>

          </div>
        </div>

      </div>
      <div className="breaker1 h-28" />
      <div className="container mx-auto border-none">
        <div className="text-zinc-900 text title text-5xl mb-10 p-10">
          FAQ
        </div>
        <div className="flex-col flex  items-center justify-items-center justify-center ">
          <div className="flex flex-col-reverse md:flex-row space-x-4 m-4 justify-start  w-3/4">
            <div className="md:pt-16">
              <Dustmon type={6} />
            </div>
            <div>
              <div className="bg-lime-300 px-10 py-4 rounded-3xl mx-10 relative title">

                <div className="absolute -left-2 -bottom-2 h-6 w-6 bg-lime-300 rounded-full" />
                What is your minting process?
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row space-x-4 m-4 justify-end w-3/4 items-end md:items-center">
            <div>
              <div className="bg-zinc-300 px-10 py-4 rounded-3xl mx-10 relative title">
                <div className="absolute -right-2 -bottom-2 h-6 w-6 bg-zinc-300 rounded-full" />
                There will be total of 10,000 collectable Dustmon tokens. We will be start our public minting on Oct 31st, 2022. Noted that first 500 token will be reserved by the team as the resources for future and follow-up projects projects.
              </div>
            </div>
            <div className="md:pt-16">
              <Dustmon type={7} filpped />
            </div>
          </div>
        </div>
        <div className="flex-col flex  items-center justify-items-center justify-center ">
          <div className="flex flex-col-reverse md:flex-row space-x-4 m-4 justify-start w-3/4">
            <div className="md:pt-16">
              <Dustmon type={9} />
            </div>
            <div>
              <div className="bg-lime-300 px-10 py-4 rounded-3xl mx-10 relative title">
                <div className="absolute -left-2 -bottom-2 h-6 w-6 bg-lime-300 rounded-full" />
                What's the price for mint?
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row space-x-4 m-4 justify-end w-3/4  items-end md:items-center">
            <div>
              <div className="bg-zinc-300 px-10 py-4 rounded-3xl mx-10 relative title">
                <div className="absolute -right-2 -bottom-2 h-6 w-6 bg-zinc-300 rounded-full" />
                Before reveal, Dustmons will be free to mint, but each time you can only mint one token. After reveal, the price will be increased by 0.001eth.
              </div>
            </div>
            <div className="md:pt-16">
              <Dustmon type={7} filpped />
            </div>
          </div>
        </div>
      </div>
      <div className="breaker2 h-28" />
      <div className="bg-zinc-900 border-none">
        <div className="container mx-auto ">
          <div className="flex-col flex items-center justify-items-center justify-center pb-10">
            <div className="title text-zinc-100">
              All Right Reserved: Dustmon 2022
            </div>

          </div>
        </div>
      </div>




    </div>
    </div >
  );
}

export default App;
